/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Helmet from 'react-helmet'
import SEO from '../components/SEO/SEO'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import select from '../components/utils'
import SimpleSlider from '../components/Gallery'

export const WorkPostTemplate = ({
    contentComponent,
    description,
    helmet,
    image,
    catalogs,
    imageAlt,
}) => {
    return (
        <section className="is-blog-section my-6">
            {helmet || ''}
            <div className="container content">
                <div className="columns is-multiline">
                    <div className="column is-2">
                        <figure className="image is-size-1-mobile">
                        <Img fluid={image} alt={imageAlt} />
                        </figure>
                    </div>
                    <div className="column is-font-secondary has-text-dark is-offset-2 is-8 is-flex">
                        <p>{description}</p>
                    </div>
                </div>
            </div>
            <div>
            <SimpleSlider images={catalogs}/>
            </div>
        </section>
    )
}

WorkPostTemplate.propTypes = {
    content: PropTypes.node.isRequired,
    contentComponent: PropTypes.func,
    description: PropTypes.string,
    title: PropTypes.string,
    helmet: PropTypes.object,
    langKey: PropTypes.string,
    image: PropTypes.object,
    catalogs: PropTypes.array,
    date: PropTypes.string,
    data: PropTypes.object,
    number: PropTypes.string,
    imageAlt: PropTypes.string,
}

const WorkPost = ({ data, location }) => {
    const { markdownRemark: post } = data
    const jsonData = data.allArticlesJson.edges[0].node.articles
    const langKey = post.frontmatter.lang
    const langFix = langKey === 'en' ? '' : 'ka'
    const image = post.frontmatter.image.childImageSharp.fluid
    const meta = data.site.siteMetadata
    const sel = select(langKey)

    return (
        <Layout
            className="container"
            data={data}
            jsonData={jsonData}
            location={location}
        >
            <SEO frontmatter={post.frontmatter} />
            <WorkPostTemplate
                content={post.html}
                contentComponent={HTMLContent}
                description={post.frontmatter.description}
                image={image}
                catalogs={post.frontmatter.catalogs.map(catalog => catalog.childImageSharp.fluid)}
                imageAlt={post.frontmatter.imageAlt}
                helmet={
                    <Helmet titleTemplate={`%s - ${meta.title}`}>
                        <title>{`${post.frontmatter.title}`}</title>
                        <meta
                            name="description"
                            content={`${post.frontmatter.description}`}
                        />
                    </Helmet>
                }
                title={post.frontmatter.title}
                date={post.frontmatter.date}
                slug={post.frontmatter.slug}
                number={post.frontmatter.number}
                langKey={langKey}
                sel={sel}
                langFix={langFix}
            />
        </Layout>
    )
}

WorkPost.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.object,
        allArticlesJson: PropTypes.object,
        site: PropTypes.object,
    }),
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
}

export default WorkPost

export const pageQuery = graphql`
    query WorkPostByID($id: String!) {
        site {
            siteMetadata {
                title
                languages {
                    langs
                    defaultLangKey
                }
            }
        }
        allArticlesJson(filter: { title: { eq: "home" } }) {
            edges {
                node {
                    articles {
                        en
                        ka
                    }
                }
            }
        }
        markdownRemark(id: { eq: $id }) {
            id
            html
            frontmatter {
                slug
                id
                title
                number
                imageAlt
                image {
                    childImageSharp {
                        fluid(maxWidth: 500) {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
                catalogs {
                    childImageSharp {
                        fluid(maxWidth: 1400, quality: 100) {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
                description
                lang
            }
        }
    }
`

